import axios from 'axios'

import {
  FETCH_ERROR,
  FETCH_WEEKLY_HOURS_SUCCESS,
} from '../../constants/ActionTypes'
export const baseUrl = process.env.REACT_APP_API_URL

export const fetchWeeklyHours = (id, date) => {
  return (dispatch) => {
    dispatch({ type: 'FETCH_START' })
    axios
      .get(
        `${baseUrl}/employees/${id}/attendance?date=${date}&&type=year-weeks`,
      )
      .then(({ data }) => {
        if (data.data) {
          dispatch({ type: FETCH_WEEKLY_HOURS_SUCCESS, payload: data.data })
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error })
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message })
      })
  }
}
