import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector, connect } from "react-redux";
import { LayoutSplashScreen } from "../../../../_metronic/layout";
import * as auth from "./authRedux";

function AuthInit(props) {
  const [showSplashScreen, setShowSplashScreen] = useState(true);
  const { access_token } = useSelector(
    ({ auth }) => ({
      access_token: auth.access_token,
    }),
    shallowEqual
  );

  // We should request user by authToken before rendering the application
  useEffect(() => {
    if (!access_token) {
      // dispatch(props.logout(undefined));
    }
    setShowSplashScreen(false);
    // eslint-disable-next-line
  }, []);

  return showSplashScreen ? <LayoutSplashScreen /> : <>{props.children}</>;
}

export default connect(null, auth.actions)(AuthInit);
