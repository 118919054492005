const INIT_STATE = {
  Month: '',
}
const saveMonth = (state = INIT_STATE, action) => {
  switch (action.type) {
    case 'SAVE_MONTH': {
      return {
        ...state,
        Month: action.payload,
      }
    }
    default:
      return state
  }
}

export default saveMonth
