import axios from "axios";

import {
  FETCH_START,
  FETCH_ERROR,
  FETCH_SUCCESS,
  FETCH_PROJECT_TASKS_SUCCESS,
} from "../../constants/ActionTypes";
export const baseUrl = process.env.REACT_APP_API_URL;
export const fetchTaskscollection = (
  resource,
  pagination,
  filters,
  sorter,
  search = ""
) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    let sort_dir = "";
    let sort_by = "";
    if (typeof sorter !== "undefined") {
      sort_dir =
        sorter.order === "descend"
          ? "desc"
          : sorter.order === "ascend"
          ? "asc"
          : "";
      sort_by = sorter.field;
    }
    const filterBy = typeof filters !== "undefined" ? filters : "";

    var queryList = [];

    if (filterBy) queryList.push(`month=${filterBy}`);
    if (pagination.current) queryList.push(`page=${pagination.current}`);
    if (pagination.pageSize) queryList.push(`per_page=${pagination.pageSize}`);
    if (pagination.sort_by) queryList.push(`order_by=${sort_by}`);
    if (pagination.sort_dir) queryList.push(`order_dir=${sort_dir}`);
    if (pagination.search) queryList.push(`search=${search}`);
    let query =
      queryList.length > 0
        ? queryList.reduce(
            (accumulator, currentValue) => accumulator + "&" + currentValue
          )
        : ``;

    axios
      .get(`${baseUrl}/${resource}?${query}`)
      .then(({ data }) => {
        if (data.data) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: FETCH_PROJECT_TASKS_SUCCESS,
            payload: data,
          });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};
