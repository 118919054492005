import { FETCH_ROLES_SUCCESS, FETCH_ROLE_SUCCESS } from "../../constants/ActionTypes";

const initialSettings = {};

export default (state = initialSettings, action) => {
	switch (action.type) {
		case FETCH_ROLES_SUCCESS: {
			return { ...state, index: action.payload };
		}
		case FETCH_ROLE_SUCCESS: {
			return { ...state, show: action.payload };
		}
		default:
			return state;
	}
};
