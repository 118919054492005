/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
// import { Dropdown } from "react-bootstrap";
// import { DropdownCustomToggler, DropdownMenu1 } from "../../dropdowns";

export function ListsWidget9({ className }) {
  return (
    <>
      <div className={`card card-custom  ${className}`}>
        {/* Header */}
        <div className="card-header align-items-center border-0 mt-4">
          <h3 className="card-title align-items-start flex-column">
            <span className="font-weight-bolder text-dark">
              Attendance
            </span>
          </h3>
        </div>
        {/* Body */}
        <div className="card-body pt-4">
          <div className="timeline timeline-6 mt-3">
            {/* <div className="timeline-item align-items-start">
              <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg">
                08:42
              </div>

              <div className="timeline-badge">
                <i className="fa fa-genderless text-warning icon-xl"></i>
              </div>

              <div className="timeline-content font-weight-bolder font-size-lg text-dark-75 pl-3">
                Ahmed Mizar{" "}
                <span style={{ fontWeight: "bold", color: "#B5B5C3" }}>
                  37<sup>&#176;</sup>
                </span>
              </div>
            </div>

            <div className="timeline-item align-items-start">
              <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg">
                10:00
              </div>

              <div className="timeline-badge">
                <i className="fa fa-genderless text-success icon-xl"></i>
              </div>

              <div className="timeline-content d-flex">
                <span className="font-weight-bolder text-dark-75 pl-3 font-size-lg">
                  Shahab{" "}
                  <span style={{ fontWeight: "bold", color: "#B5B5C3" }}>
                    38<sup>&#176;</sup>
                  </span>
                </span>
              </div>
            </div>

            <div className="timeline-item align-items-start">
              <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg">
                14:37
              </div>

              <div className="timeline-badge">
                <i className="fa fa-genderless text-danger icon-xl"></i>
              </div>

              <div className="timeline-content font-weight-bolder font-size-lg text-dark-75 pl-3">
                Sayed Ibrahim{" "}
                <span style={{ fontWeight: "bold", color: "#B5B5C3" }}>
                  37<sup>&#176;</sup>
                </span>
              </div>
            </div>

            <div className="timeline-item align-items-start">
              <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg">
                16:50
              </div>

              <div className="timeline-badge">
                <i className="fa fa-genderless text-primary icon-xl"></i>
              </div>

              <div className="timeline-content font-weight-bolder font-size-lg text-dark-75 pl-3">
                Ali Mohmmed{" "}
                <span style={{ fontWeight: "bold", color: "#B5B5C3" }}>
                  39<sup>&#176;</sup>
                </span>
              </div>
            </div>

            <div className="timeline-item align-items-start">
              <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg">
                21:03
              </div>

              <div className="timeline-badge">
                <i className="fa fa-genderless text-danger icon-xl"></i>
              </div>

              <div className="timeline-content font-weight-bolder text-dark-75 pl-3 font-size-lg">
                Khaled{" "}
                <span style={{ fontWeight: "bold", color: "#B5B5C3" }}>
                  37<sup>&#176;</sup>
                </span>
              </div>
            </div>

            <div className="timeline-item align-items-start">
              <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg">
                23:07
              </div>

              <div className="timeline-badge">
                <i className="fa fa-genderless text-info icon-xl"></i>
              </div>

              <div className="timeline-content font-weight-bolder font-size-lg text-dark-75 pl-3">
                Ayman{" "}
                <span style={{ fontWeight: "bold", color: "#B5B5C3" }}>
                  37<sup>&#176;</sup>
                </span>
              </div>
            </div>

            <div className="timeline-item align-items-start">
              <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg">
                16:50
              </div>

              <div className="timeline-badge">
                <i className="fa fa-genderless text-primary icon-xl"></i>
              </div>

              <div className="timeline-content font-weight-bolder font-size-lg text-dark-75 pl-3">
                Amir Ahmed{" "}
                <span style={{ fontWeight: "bold", color: "#B5B5C3" }}>
                  37<sup>&#176;</sup>
                </span>
              </div>
            </div>

            <div className="timeline-item align-items-start">
              <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg">
                21:03
              </div>

              <div className="timeline-badge">
                <i className="fa fa-genderless text-danger icon-xl"></i>
              </div>

              <div className="timeline-content font-weight-bolder font-size-lg text-dark-75 pl-3">
                Yara ALi{" "}
                <span style={{ fontWeight: "bold", color: "#B5B5C3" }}>
                  37<sup>&#176;</sup>
                </span>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}
