import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { useSelector } from "react-redux";
import { DashboardPage } from "./pages/DashboardPage";

const Administration = lazy(() => import("./modules/Administration"));
const Employees = lazy(() => import("./modules/Employees"));
const PayRoll = lazy(() => import("./modules/PayRoll"));
const Configuration = lazy(() => import("./modules/Configuration"));
const Requests = lazy(() => import("./modules/Requests"));
const Attendances = lazy(() => import("./modules/Attendances"));
const History = lazy(() => import("./modules/History"));

const EmployeeHome = lazy(() => import("./modules/EmployeeHome"));
const EmployeeInfo = lazy(() => import("./modules/Employees/Details"));
const EmployeeLeaves = lazy(() => import("./modules/EmployeeHome/Leaves"));
const EmployeeApprovals = lazy(() => import("./modules/EmployeeHome/Approvals"));
const EmployeePayslips = lazy(() => import("./modules/EmployeeHome/Payslips"));
const EmployeeSalary = lazy(() => import("./modules/EmployeeHome/Salary"));
const EmployeeAttandance = lazy(() => import("./modules/EmployeeHome/Attandance"));
const EmployeeWeeklyHours = lazy(() => import("./modules/EmployeeHome/WeeklyHours"));
const EmployeeTasks = lazy(() => import("./modules/EmployeeHome/Tasks"));
const EmployeeTasksHistory = lazy(() => import("./modules/EmployeeHome/TasksHistory"));
const EmployeeHistory = lazy(() => import("./modules/EmployeeHome/History"));

const MyProfile = lazy(() => import("./modules/MyProfile"));
const Projects = lazy(() => import("./modules/Projects"));
const Proposals = lazy(() => import("./modules/Proposals"));
const WorkFlow = lazy(() => import("./modules/Workflow"));
const Forms = lazy(() => import("./modules/Forms"));

const PDF = lazy(() => import("../_metronic/layout/components/pdfExport"));
const ContractPDF = lazy(() =>
  import("../_metronic/layout/components/FormPDF")
);

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  const { isEmployee } = useSelector(({ auth }) => ({
    isEmployee: auth.user?.roles?.includes(2),
  }));

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {/* Redirect from root URL to /dashboard. */
        isEmployee ? (
          <Redirect exact from="/" to="/employee-home" />
        ) : (
          <Redirect exact from="/" to="/dashboard" />
        )}
        <Route path="/dashboard" component={DashboardPage} />
        <ContentRoute
          auth={!isEmployee}
          path="/administration"
          component={Administration}
        />
        <Route path="/export-pdf" component={PDF} />
        <Route path="/contract" component={ContractPDF} />
        <ContentRoute
          auth={!isEmployee}
          path="/employees"
          component={Employees}
        />
        <ContentRoute
          auth={!isEmployee}
          path="/requests"
          component={Requests}
        />
        <ContentRoute auth={!isEmployee} path="/payroll" component={PayRoll} />
        <ContentRoute
          auth={!isEmployee}
          path="/attendances"
          component={Attendances}
        />
        <ContentRoute auth={!isEmployee} path="/logs" component={History} />
        <ContentRoute
          auth={!isEmployee}
          path="/configuration"
          component={Configuration}
        />

        <ContentRoute
          exact
          auth={isEmployee}
          path="/employee-home"
          component={EmployeeHome}
        />
        <ContentRoute
          exact
          auth={isEmployee}
          path="/my-info"
          component={EmployeeInfo}
        />
        <ContentRoute
          exact
          auth={isEmployee}
          path="/my-leaves"
          component={EmployeeLeaves}
        />
        <ContentRoute
          exact
          auth={isEmployee || !isEmployee}
          path="/my-approvals"
          component={EmployeeApprovals}
        />
        <ContentRoute
          exact
          auth={isEmployee}
          path="/my-payslips"
          component={EmployeePayslips}
        />
        <ContentRoute
          exact
          auth={isEmployee}
          path="/my-salary"
          component={EmployeeSalary}
        />
        <ContentRoute
          exact
          auth={isEmployee}
          path="/my-salary/:year/:month"
          component={EmployeeSalary}
        />
        <ContentRoute
          exact
          auth={isEmployee}
          path="/my-attandance/attandance"
          component={EmployeeAttandance}
        />
        <ContentRoute
          exact
          auth={isEmployee}
          path="/my-attandance/weekly-hours"
          component={EmployeeWeeklyHours}
        />
        <ContentRoute
          exact
          auth={isEmployee}
          path="/my-tasks"
          component={EmployeeTasks}
        />
        <ContentRoute
          exact
          auth={isEmployee}
          path="/my-tasks/:year/:week"
          component={EmployeeTasks}
        />
        <ContentRoute
          exact
          auth={isEmployee}
          path="/tasks-history"
          component={EmployeeTasksHistory}
        />
        <ContentRoute
          exact
          auth={isEmployee}
          path="/my-history"
          component={EmployeeHistory}
        />
        <ContentRoute auth={true} path="/user-profile" component={MyProfile} />
        <ContentRoute
          auth={!isEmployee}
          path="/time-cards"
          component={Projects}
        />
        <ContentRoute
          auth={!isEmployee}
          path="/proposals"
          component={Proposals}
        />
        <ContentRoute
          auth={!isEmployee}
          path="/work-flow"
          component={WorkFlow}
        />
        <ContentRoute auth={!isEmployee} path="/forms" component={Forms} />
      </Switch>
    </Suspense>
  );
}
