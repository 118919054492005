import {
  FETCH_DEPARTMENTS_SUCCESS,
  FETCH_DEPARTMENT_SUCCESS,
} from '../../constants/ActionTypes'

const initialSettings = {}

export default (state = initialSettings, action) => {
  switch (action.type) {
    case FETCH_DEPARTMENTS_SUCCESS: {
      return { ...state, index: action.payload }
    }
    case FETCH_DEPARTMENT_SUCCESS: {
      return { ...state, show: action.payload }
    }
    default:
      return state
  }
}
