import { FETCH_ATTENDANCE_CONFIGS_SUCCESS } from '../../constants/ActionTypes'

const initialSettings = {}

export default (state = initialSettings, action) => {
  switch (action.type) {
    case FETCH_ATTENDANCE_CONFIGS_SUCCESS: {
      return { ...state, index: action.payload.data }
    }

    default:
      return state
  }
}
