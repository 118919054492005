import {
  FETCH_ATTENDANCES_SUCCESS,
  FETCH_ATTENDANCE_SUCCESS,
} from '../../constants/ActionTypes'

const initialSettings = {}

export default (state = initialSettings, action) => {
  switch (action.type) {
    case FETCH_ATTENDANCES_SUCCESS: {
      return { ...state, index: action.payload }
    }
    case FETCH_ATTENDANCE_SUCCESS: {
      return { ...state, show: action.payload }
    }
    default:
      return state
  }
}
