import axios from "axios";

import {
  FETCH_ERROR,
  FETCH_TREE_STRUCTURE_SUCCESS,
} from "../../constants/ActionTypes";
export const baseUrl = process.env.REACT_APP_API_URL;

export const fetchDepartmentTree = () => {
  return (dispatch) => {
    dispatch({ type: "FETCH_START" });
    axios
      .get(`${baseUrl}/departments/tree`)
      .then((response) => {
        dispatch({
          type: FETCH_TREE_STRUCTURE_SUCCESS,
          payload: response.data,
        });
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};
