import React from "react";
import { useField, useFormikContext } from "formik";
import { DatePicker, Form } from "antd";
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";

import dayjs from "dayjs";
const FormItem = Form.Item;
const getFieldCSSClasses = (touched, errors) => {
  const classes = ["form-control"];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

export function DateInput({
  field,
  form: { touched, errors, setFieldValue },
  withFeedbackLabel = true,
  customFeedbackLabel,
  ...props
}) {
  const errorMsg = touched[field.name] && errors[field.name];
  const validateStatus = errorMsg
    ? "error"
    : touched[field.name] && !errors[field.name]
    ? "success"
    : undefined;

  return (
    <>
      {props.label && <label>{props.label}</label>}
      <FormItem help={errorMsg} validateStatus={validateStatus} hasFeedback>
        <DatePicker
          onChange={(date) => {
            setFieldValue(field.name, date.format("YYYY-MM-DD"));
          }}
          defaultValue={dayjs()}
        />
      </FormItem>
      {withFeedbackLabel && (
        <FieldFeedbackLabel
          error={errors[field.name]}
          touched={touched[field.name]}
          label={props.label}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}
