import React from "react";
import {
  MixedWidget1,
  MixedWidget14,
  ListsWidget9,
  // StatsWidget11,
  // StatsWidget12,
  // ListsWidget1,
  AdvanceTablesWidget2,
  AdvanceTablesWidget4,
  ListsWidget3,
  // ListsWidget4,
  // ListsWidget8,
} from "../../_metronic/_partials/widgets";

import { ApprovalsWidget } from "../modules/EmployeeHome/Dashboard/ApprovalsWidget";
import { TasksWidget } from "../modules/EmployeeHome/Dashboard/TasksWidget";

import { useQuery } from "react-query"
import { fetchDashboard } from "../../services";

export function DashboardPage() {
  const { data, isLoading, error } = useQuery(["dashboardData"], () => fetchDashboard(), {
    cacheTime: 60 * 1000, staleTime: 30 * 1000
  })

  let pending_approvals = [];
  let not_submitted_tasks_count = [];
  let projects = [];

  if (!isLoading && !error) {
    pending_approvals = data.data.data.pending_approvals;
    not_submitted_tasks_count = data.data.data.not_submitted_tasks_count;
    projects = data.data.data.projects;
  }

  return (
    <>
      <div className="row">
        <div className="col-lg-6 col-xxl-4">
          <MixedWidget1 className="card-stretch gutter-b" />
        </div>
        <div className="col-lg-6 col-xxl-4">
          <ListsWidget9 className="card-stretch gutter-b" />
        </div>

        {/* <div className="col-lg-6 col-xxl-4">
          <StatsWidget11
            className="card-stretch card-stretch-half gutter-b"
            symbolShape="circle"
            baseColor="success"
          />
          <StatsWidget12 className="card-stretch card-stretch-half gutter-b" />
        </div> */}

        <div className="col-xxl-8 order-2 order-xxl-1">
          {!isLoading && <AdvanceTablesWidget2 className="card-stretch gutter-b" data={projects} />}
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4">
          {!isLoading && <ApprovalsWidget className="card-stretch gutter-b" data={pending_approvals} />}
        </div>
        <div className="col-lg-4">
          {!isLoading && <TasksWidget className="card-stretch gutter-b" data={not_submitted_tasks_count} />}
        </div>
        <div className="col-lg-4">
          <ListsWidget3 className="card-stretch gutter-b" />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-8">
          <AdvanceTablesWidget4 className="card-stretch gutter-b" />
        </div>
      </div>
    </>
  );
}
