import React from 'react'
import { Route } from 'react-router-dom'
import { Content } from './Content'
import { Error403 } from '../../../../app/modules/ErrorsExamples/Error403'
import { Error404 } from '../../../../app/modules/ErrorsExamples/Error404'
export function ContentRoute({ children, component, render, auth, ...props }) {
  if (auth) {
    return (
      <Route {...props}>
        {(routeProps) => {
          if (typeof children === 'function') {
            return <Content>{children(routeProps)}</Content>
          }

          if (!routeProps.match) {
            return null
          }

          if (children) {
            return <Content>{children}</Content>
          }

          if (component) {
            return (
              <Content>{React.createElement(component, routeProps)}</Content>
            )
          }

          if (render) {
            return <Content>{render(routeProps)}</Content>
          }

          return null
        }}
      </Route>
    )
  } else if (auth === false) {
    return (
      <Route {...props}>
        {(routeProps) => {
          return <Content>{React.createElement(Error403, routeProps)}</Content>
        }}
      </Route>
    )
  } else if (typeof auth === 'undefined') {
    return (
      <Route {...props}>
        {(routeProps) => {
          return <Content>{React.createElement(Error404, routeProps)}</Content>
        }}
      </Route>
    )
  }
}
