// import { snakeCase } from "lodash";
import axios from "axios";

export const baseUrl = process.env.REACT_APP_API_URL;

export const fetchLists = (model) => {
  return axios.get(`${baseUrl}/${model}/lists`);
};

export const fetchTasks = (from, to) => {
  return axios.get(
    `${baseUrl}/tasks?per_page=1000&order_dir=asc&filters={"employee":"self","date":{"from": "${from}", "to": "${to}"}}`
  );
};
export const fetchTaskSummeryService = (filters) => {
  console.log('mmmmm',filters)
  const cleanFilters = JSON.parse(JSON.stringify(filters));
  // Remove keys with empty values
  Object.keys(cleanFilters).forEach((key) => {
    if (!cleanFilters[key]) {
      delete cleanFilters[key];
    }
  });
  return axios.get(
    `${baseUrl}/tasks/summery?filters=${JSON.stringify(cleanFilters)}`
  );
};

export const fetchEmployee = (id) => {
  return axios.get(`${baseUrl}/employees/${id}`);
};

export const fetchTasksByEmployeeId = (id, from, to) => {
  return axios.get(
    `${baseUrl}/tasks?per_page=1000&order_dir=asc&filters={"employee":${id}, "date":{"from": "${from}", "to": "${to}"}}`
  );
};

export const saveTask = (task) => {
  if (task.id) {
    return axios.put(`${baseUrl}/tasks/${task.id}`, task);
  } else {
    return axios.post(`${baseUrl}/tasks`, task);
  }
};

export const deleteTask = (id) => {
  if (id) {
    return axios.delete(`${baseUrl}/tasks/${id}`);
  }
};

// WorkFlow Section
export const fetchWorkFlowLists = () => {
  return axios.get(`${baseUrl}/approval-settings/lists`);
};

export const saveWorkFlow = (workFlow) => {
  return axios.post(`${baseUrl}/approval-settings`, workFlow);
};

export const deleteWorkFlow = (id) => {
  if (id) {
    return axios.delete(`${baseUrl}/approval-settings/${id}`);
  }
};

export const fetchCollection = (
  resource,
  pagination,
  sorter,
  search
) => {
  let sort_dir = "";
  let sort_by = "";
console.log('hhhh',search,pagination)
  if (typeof sorter !== "undefined") {
    sort_dir =
      sorter.order === "descend"
        ? "desc"
        : sorter.order === "ascend"
        ? "asc"
        : "";
    sort_by = sorter.field;
  }
  

  var queryList = [];

  
  if (pagination.current) queryList.push(`page=${pagination.current}`);
  if (pagination.pageSize) queryList.push(`per_page=${pagination.pageSize}`);
  if (pagination.sort_by) queryList.push(`order_by=${sort_by}`);
  if (pagination.sort_dir) queryList.push(`order_dir=${sort_dir}`);
  if (search) queryList.push(`search=${search}`);
  let query =
    queryList.length > 0
      ? queryList.reduce(
          (accumulator, currentValue) => accumulator + "&" + currentValue
        )
      : ``;

  return axios.get(`${baseUrl}/${resource}?${query}`);
};

export const fetchDashboard = () => {
  return axios.get(`${baseUrl}/employees/dashboard`);
};

export const fetchDailyShifts = () => {
  return axios.get(`${baseUrl}/shifts`);
};

export const deleteDailyShifts = (id) => {
  return axios.delete(`${baseUrl}/shifts/${id}`);
};

export const fetchDailyShiftById = (id) => {
  return axios.get(`${baseUrl}/shifts/${id}`);
};

export const fetchDailyShiftsLists = () => {
  return axios.get(`${baseUrl}/shifts/lists`);
};

export const saveDailyShift = (shift) => {
  return axios.post(`${baseUrl}/shifts`, shift);
};

export const updateDailyShift = (id, shift) => {
  return axios.put(`${baseUrl}/shifts/${id}`, shift);
};

export const fetchWeeklyShifts = () => {
  return axios.get(`${baseUrl}/week-schedules`);
};

export const deleteWeeklyShifts = (id) => {
  return axios.delete(`${baseUrl}/week-schedules/${id}`);
};

export const fetchWeeklyShiftById = (id) => {
  return axios.get(`${baseUrl}/week-schedules/${id}`);
};

export const fetchWeeklyShiftsLists = () => {
  return axios.get(`${baseUrl}/week-schedules/lists`);
};

export const saveWeeklyShift = (shift) => {
  return axios.post(`${baseUrl}/week-schedules`, shift);
};

export const updateWeeklyShift = (id, shift) => {
  return axios.put(`${baseUrl}/week-schedules/${id}`, shift);
};

export const fetchMonthlyShifts = () => {
  return axios.get(`${baseUrl}/month-schedules`);
};

export const deleteMonthlyShifts = (id) => {
  return axios.delete(`${baseUrl}/month-schedules/${id}`);
};

export const fetchMonthlyShiftById = (id) => {
  return axios.get(`${baseUrl}/month-schedules/${id}`);
};

export const fetchMonthlyShiftsLists = () => {
  return axios.get(`${baseUrl}/month-schedules/lists`);
};

export const saveMonthlyShift = (shift) => {
  return axios.post(`${baseUrl}/month-schedules`, shift);
};

export const updateMonthlyShift = (id, shift) => {
  return axios.put(`${baseUrl}/month-schedules/${id}`, shift);
};

export const getMyApprovals = (perPage, filters) => {
console.log('seeeefilters',filters)
  let allFilters = filters?{ ...filters, approver: "self", status: "pending" }:{ approver: "self", status: "pending" };
  return axios.get(
    `${baseUrl}/approvals?per_page=${perPage}&filters=${JSON.stringify(allFilters)}`
  );
};

export const submitApprovals = (data) => {
  return axios.post(`${baseUrl}/approvals`, data);
};

export const syncAttendances = () => {
  return axios.post(`${baseUrl}/attendances/sync`);
};

export const approveApproval = (id) => {
  return axios.put(`${baseUrl}/approvals/${id}`, { status: "approved" });
};

export const rejectApproval = (id) => {
  return axios.put(`${baseUrl}/approvals/${id}`, { status: "rejected" });
};
