import React from "react";
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";
import { get } from 'lodash'

const getFieldCSSClasses = (touched, errors) => {
  const classes = ["form-control"];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

export function Input({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  withFeedbackLabel = true,
  customFeedbackLabel,
  type = "text",
  ...props
}) {
  const touchedResult = get(touched, field.name);
  const errorsResult = get(errors, field.name);

  const numberInputOnWheelPreventChange = (e) => {
    if (e.currentTarget === document?.activeElement) {
      const el = document.activeElement;

      // Prevent the input value change
      el.blur()
    
      // Prevent the page/container scrolling
      e.stopPropagation()
    
      // Refocus immediately, on the next tick (after the current function is done)
      setTimeout(() => {
        el.focus({ preventScroll: true })
      }, 0)
    }
  }

  return (
    <>
      {label && <label>{label}</label>}
      {type !== 'textarea' && type !== 'password'  && type !== 'number' && <input
        type={type}
        className={getFieldCSSClasses(touchedResult, errorsResult)}
        {...field}
        {...props}
      />}
      {type === 'number' && <input
        type='number'
        className={getFieldCSSClasses(touchedResult, errorsResult)}
        onWheel={numberInputOnWheelPreventChange}
        {...field}
        {...props}
      />}
      {type === 'password' && <input
        type='password'
        autoComplete="new-password"
        className={getFieldCSSClasses(touchedResult, errorsResult)}
        {...field}
        {...props}
      />}
      {type === 'textarea' && <textarea
        className={getFieldCSSClasses(touchedResult, errorsResult)}
        {...field}
        {...props}
      />}
      {withFeedbackLabel && (
        <FieldFeedbackLabel
          error={errorsResult}
          touched={touchedResult}
          label={label}
          type={type}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}
