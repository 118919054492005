import axios from "axios";

import {
  FETCH_CONTRACT_DATA_SUCCESS,
  FETCH_JOB_OFFER_SUCCESS,
  SHOW_MESSAGE,
  FETCH_START,
} from "../../constants/ActionTypes";
export const baseUrl = process.env.REACT_APP_API_URL;

export const sendFormData = (data, type) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .post(`${baseUrl}/exports/${type}`, data)
      .then(({data}) => {
        if (data.link) {
          dispatch({ type: SHOW_MESSAGE, payload: data.message });
          window.open(data.link)
        }
        // else {
        //   dispatch({ type: "SHOW_ERROR", payload: data.error });
        // }
        // console.log("response", response);
      })
      .catch(function(error) {
        dispatch({ type: "SHOW_ERROR", payload: error.message });
      });
  };
};
