import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import {
  FETCH_CONTRACT_DATA_SUCCESS,
  FETCH_JOB_OFFER_SUCCESS,
} from "../../constants/ActionTypes";

const initialforms = {
  contarct_data: undefined,
  job_offer_dat: undefined,
};

const Forms = persistReducer(
  { storage, key: "forms", whitelist: ["contarct_data", "job_offer_dat"] },

  (state = initialforms, action) => {
    switch (action.type) {
      case FETCH_CONTRACT_DATA_SUCCESS: {
        return { ...state, contarct_data: action.payload };
      }

      default:
        return state;
    }
  }
);
export default Forms;

// export default (state = initialSettings, action) => {
//   switch (action.type) {
//     case FETCH_CONTRACT_DATA_SUCCESS: {
//       return { ...state, contract_data: action.payload };
//     }

//     default:
//       return state;
//   }
// };
