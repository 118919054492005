import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { logout } from "./authCrud";

import { useQueryClient } from "react-query";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  SetUser: "[Set User] Action",
};

const initialAuthState = {
  user: undefined,
  access_token: undefined,
};

export const reducer = persistReducer(
  { storage, key: "v726-demo1-auth", whitelist: ["access_token", "user"] },

  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { access_token } = action.payload.token;
        return { access_token, user: action.payload.user };
      }

      case actionTypes.Register: {
        const { access_token } = action.payload;

        return { access_token, user: undefined };
      }

      case actionTypes.Logout: {
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;
        return { ...state, user };
      }

      case actionTypes.SetUser: {
        const { user } = action.payload;
        return { ...state, user };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (data) => ({ type: actionTypes.Login, payload: data }),
  register: (access_token) => ({
    type: actionTypes.Register,
    payload: { access_token },
  }),
  logout: () => {
    return async (dispatch) => {
      // const queryClient = useQueryClient()
      // queryClient.removeQueries();
      await logout();
      dispatch({ type: actionTypes.Logout });
    };
  },
  // requestUser: (user) => ({
  //   type: actionTypes.UserRequested,
  //   payload: { user },
  // }),
  // fulfillUser: (user) => ({ type: actionTypes.UserLoaded, payload: { user } }),
  // setUser: (user) => ({ type: actionTypes.SetUser, payload: { user } }),
};

export function* saga() {
  //   yield takeLatest(actionTypes.Logout, function* logoutSaga() {
  //     yield call(logout, null)
  //     yield put({type: actionTypes.LogoutComplete})
  //   })
  // yield takeLatest(actionTypes.Login, function* loginSaga() {
  //   yield put(actions.requestUser())
  // })
  // yield takeLatest(actionTypes.Register, function* registerSaga() {
  //   yield put(actions.requestUser())
  // })
  // yield takeLatest(actionTypes.UserRequested, function* userRequested() {
  //   const { data: user } = yield getUserByToken()
  //   yield put(actions.fulfillUser(user))
  // })
}
