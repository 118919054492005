import axios from "axios";

import {
  FETCH_START,
  FETCH_ERROR,
  FETCH_SUCCESS,
} from "../../constants/ActionTypes";
export const baseUrl = process.env.REACT_APP_API_URL;

export const ChangePassword = (id, payload, callback = (res) => res) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .post(`${baseUrl}/users/${id}/change-password`, payload)
      .then(({ data }) => {
        if (data.message) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: "SHOW_MESSAGE", payload: data.message });
          callback(200);
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
        }
      })
      .catch(function(error) {
        let body = error.response.data;
        let message = body ? body.message : error.message;
        dispatch({ type: FETCH_ERROR, payload: message });
        callback(body);
      });
  };
};
