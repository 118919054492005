import { FETCH_TIMESHEET_SUCCESS } from '../../constants/ActionTypes'

const initialSettings = {}

export default (state = initialSettings, action) => {
  switch (action.type) {
    case FETCH_TIMESHEET_SUCCESS: {
      return { ...state, index: action.payload }
    }

    default:
      return state
  }
}
