export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    (config) => {
      const {
        auth: { access_token },
      } = store.getState()
      if (access_token) {
        config.headers.Authorization = `Bearer ${access_token}`
      }

      return config
    },
    (err) => Promise.reject(err),
  )

  // API respone interceptor
  axios.interceptors.response.use(
    (response) => {
      return response
    },

    (error) => {
      // Remove token and redirect
      if (error.response.status === 401) {
        store.dispatch({ type: '[Logout] Action' })
      }

      return Promise.reject(error)
    },
  )
}
