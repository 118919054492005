import React from 'react'
import { notification } from 'antd'
import { useSelector, useDispatch } from 'react-redux'

const Notifications = () => {
  const message = useSelector(({ common }) => common.message)
  const error = useSelector(({ common }) => common.error)
  const dispatch = useDispatch()
  setTimeout(() => {
    dispatch({ type: 'SHOW_ERROR', payload: null })
    dispatch({ type: 'SHOW_MESSAGE', payload: null })
  }, 5000)
  if (message) {
    notification.info({
      message: message,
      className: 'custom-class',
    })
  }
  if (error) {
    notification.error({
      message: error,
      className: 'custom-class',
    })
  }

  return <div />
}

export default Notifications
