import {
	FETCH_BONUSES_SUCCESS,
	FETCH_BONUSE_SUCCESS,
} from "../../constants/ActionTypes";

const initialSettings = {};

export default (state = initialSettings, action) => {
	switch (action.type) {
		case FETCH_BONUSES_SUCCESS: {
			return { ...state, index: action.payload };
		}
		case FETCH_BONUSE_SUCCESS: {
			return { ...state, show: action.payload };
		}
		default:
			return state;
	}
};
