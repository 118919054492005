/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import { Table } from "antd";
import moment from "moment";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";
import { fetchCollection } from "../../../../redux/actions";

const monthFormat = "YYYY-MM";

export function AdvanceTablesWidget4({ className }) {
  let dispatch = useDispatch();
  let salaries = useSelector((state) => state.salaries);
  const { loading } = useSelector((state) => state.common);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    total: 10,
  });
  const [monthFilter, setMonthFilter] = useState(
    moment(new Date()).format(monthFormat)
  );
  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(fetchCollection("salaries", pagination, monthFilter));
  }, []);

  useEffect(() => {
    if (salaries.index) {
      setData(salaries.index.data);
      setPagination({ ...pagination, total: salaries.index.meta.total });
    }
  }, [salaries]);

  let handleChange = (pagination) => {
    dispatch(fetchCollection("salaries", pagination, monthFilter));
    setPagination(pagination);
  };

  const columns = [
    {
      title: "Employee Name",
      dataIndex: "name",
      key: "name",

      render: (text, record) => (
        <div className="d-flex align-items-center">
          <div className="symbol symbol-50 symbol-light mr-4">
            <span className="symbol-label">
              <span className="svg-icon h-75 align-self-end">
                <SVG src={toAbsoluteUrl("/media/svg/avatars/001-boy.svg")} />
              </span>
            </span>
          </div>
          <div>
            <span className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">
              {text}
            </span>
          </div>
        </div>
      ),
    },
    {
      title: "Basic Salary",
      dataIndex: "basic_salary",
      key: "basic_salary",
      render: (text, record) => (
        <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
          {text}
        </span>
      ),
    },
    {
      title: "Month",
      dataIndex: "month",
      key: "month",
      render: (text, record) => (
        <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
          {monthFilter}
        </span>
      ),
    },
    {
      title: "Payment Status",
      dataIndex: "paid",
      render: (text, record) => (
        <>
          {record.paid ? (
            <span className="label label-lg label-light-primary label-inline">
              Paid
            </span>
          ) : (
            <span className="label label-lg label-light-danger label-inline">
              Not Paid
            </span>
          )}
        </>
      ),
    },
  ];
  return (
    <div className={`card card-custom ${className}`}>
      {/* Head */}
      <div className="card-header border-0 py-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label font-weight-bolder text-dark">
            Payslips
          </span>
        </h3>
        {/* <div className="card-toolbar">
          <a
            href="#"
            className="btn btn-info font-weight-bolder font-size-sm mr-3"
          >
            New Report
          </a>
          <a
            href="#"
            className="btn btn-danger font-weight-bolder font-size-sm"
          >
            Create
          </a>
        </div> */}
      </div>
      {/* Body */}
      <div className="card-body pt-0 pb-3">
        <div className="table-responsive">
          <Table
            className="payslipsTable-home table table-borderless table-vertical-center"
            rowKey={(record) => record.id}
            columns={columns}
            dataSource={data}
            pagination={pagination}
            onChange={handleChange}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
}
