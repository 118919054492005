import {
  FETCH_PROJECT_TASKS_SUCCESS,
  FETCH_PROJECT_TASK_SUCCESS,
} from "../../constants/ActionTypes";

const initialSettings = {};

export default (state = initialSettings, action) => {
  switch (action.type) {
    case FETCH_PROJECT_TASKS_SUCCESS: {
      return { ...state, index: action.payload };
    }
    case FETCH_PROJECT_TASK_SUCCESS: {
      return { ...state, show: action.payload };
    }
    default:
      return state;
  }
};
