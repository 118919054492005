import axios from 'axios'

import { SHOW_MESSAGE, FETCH_START } from '../../constants/ActionTypes'
export const baseUrl = process.env.REACT_APP_API_URL

export const checkInOut = (id) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START })
    axios
      .post(`${baseUrl}/attendances/${id}/check`)
      .then(({ data }) => {
        if (data.message) {
          dispatch({ type: SHOW_MESSAGE, payload: data.message })
        } else {
          dispatch({ type: 'SHOW_ERROR', payload: data.error })
        }
      })
      .catch(function (error) {
        dispatch({ type: 'SHOW_ERROR', payload: error.message })
      })
  }
}
