import React from "react";
import { useField, useFormikContext } from "formik";
import { DatePicker } from "antd";
import dayjs from 'dayjs';
const getFieldCSSClasses = (touched, errors) => {
  const classes = ["form-control"];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

export function DatePickerField({ ...props }) {
  const { setFieldValue, errors, touched } = useFormikContext();
  const [field] = useField(props);
  return (
    <>
      <div>{props.label && <label>{props.label}</label>}</div>
      <DatePicker
        className={getFieldCSSClasses(touched[field.name], errors[field.name])}
        style={{ width: "100%" }}
        onChange={(val) => {
          setFieldValue(
            field.name,
            val ? (props.showTime ? val.format("YYYY-MM-DD HH:mm") : val.format("YYYY-MM-DD")) : ""
          );
        }}
        //defaultValue={[dayjs('2015/01/01', dateFormat), dayjs('2015/01/01', dateFormat)]}
        format={props.showTime ? 'YYYY-MM-DD hh:mm a' : 'YYYY-MM-DD'}
        showTime={props.showTime && { format: "hh:mm a" }}
        value={field.value ? (props.showTime ? dayjs(field.value, "YYYY-MM-DD HH:mm") : dayjs(field.value, "YYYY-MM-DD")) : ""}
      />
      {errors[field.name] && touched[field.name] ? (
        <div className="invalid-datepicker-feedback">
          {errors[field.name].toString()}
        </div>
      ) : (
        <div className="feedback">
          Please enter <b>{props.label}</b> in {props.showTime ? 'yyyy-mm-dd hh:mm' : 'yyyy-mm-dd'} format
        </div>
      )}
    </>
  );
}
