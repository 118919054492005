import { FETCH_USERS_SUCCESS, FETCH_USER_SUCCESS } from "../../constants/ActionTypes";

const initialSettings = {};

export default (state = initialSettings, action) => {
	switch (action.type) {
		case FETCH_USERS_SUCCESS: {
			return { ...state, index: action.payload };
		}
		case FETCH_USER_SUCCESS: {
			return { ...state, show: action.payload };
		}
		default:
			return state;
	}
};
