import {
  FETCH_JOBS_SUCCESS,
  FETCH_JOB_SUCCESS,
} from '../../constants/ActionTypes'

const initialSettings = {}

export default (state = initialSettings, action) => {
  switch (action.type) {
    case FETCH_JOBS_SUCCESS: {
      return { ...state, index: action.payload }
    }
    case FETCH_JOB_SUCCESS: {
      return { ...state, show: action.payload }
    }
    default:
      return state
  }
}
