import { snakeCase } from "lodash";
import axios from "axios";

import {
  FETCH_START,
  FETCH_ERROR,
  FETCH_SUCCESS,
} from "../../constants/ActionTypes";
export const baseUrl = process.env.REACT_APP_API_URL;
export const fetchCollection = (
  resource,
  pagination,
  filters,
  sorter,
  search = ""
) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    let sort_dir = "";
    let sort_by = "";
    if (typeof sorter !== "undefined") {
      sort_dir =
        sorter.order === "descend"
          ? "desc"
          : sorter.order === "ascend"
          ? "asc"
          : "";
      sort_by = sorter.field;
    }
    const filterBy = typeof filters !== "undefined" ? filters : "";

    var queryList = [];

    if (filterBy) queryList.push(`month=${filterBy}`);
    if (pagination.current) queryList.push(`page=${pagination.current}`);
    if (pagination.pageSize) queryList.push(`per_page=${pagination.pageSize}`);
    if (pagination.sort_by) queryList.push(`order_by=${sort_by}`);
    if (pagination.sort_dir) queryList.push(`order_dir=${sort_dir}`);
    if (pagination.search) queryList.push(`search=${search}`);
    let query =
      queryList.length > 0
        ? queryList.reduce(
            (accumulator, currentValue) => accumulator + "&" + currentValue
          )
        : ``;

    axios
      .get(`${baseUrl}/${resource}?${query}`)
      .then(({ data }) => {
        if (data.data) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: `FETCH_${snakeCase(resource).toUpperCase()}_SUCCESS`,
            payload: data,
          });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const fetchResource = (resource, id, singular) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_START" });
    axios
      .get(`${baseUrl}/${resource}/${id}`)
      .then(({ data }) => {
        if (data.data) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: `FETCH_${singular.toUpperCase()}_SUCCESS`,
            payload: data.data,
          });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const updateResource = (
  resource,
  id,
  payload,
  callback = (res) => res
) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .put(`${baseUrl}/${resource}/${id}`, payload)
      .then(({ data }) => {
        if (data.message) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: "SHOW_MESSAGE", payload: data.message });
          callback(200);
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
        }
      })
      .catch(function(error) {
        let body = error.response?.data;
        let message = body ? body.message : error.message;
        dispatch({ type: FETCH_ERROR, payload: message });
        callback(body);
      });
  };
};
export const cloneResource = (
  resource,
  id,
  payload,
  callback = (res) => res
) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .post(`${baseUrl}/${resource}/${id}/clone`, payload)
      .then(({ data }) => {
        if (data.message) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: "SHOW_MESSAGE", payload: data.message });
          callback(200);
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
        }
      })
      .catch(function(error) {
        let body = error.response?.data;
        // let message = body ? body.message : error.message
        // console.log('Error****:', message)
        // dispatch({ type: FETCH_ERROR, payload: message })
        callback(body);
      });
  };
};

export const createResource = (
  resource,
  payload,
  callback = (res) => res,
  onSuccess = (response) => response
) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .post(`${baseUrl}/${resource}`, payload)
      .then(({ data }) => {
        if (data.message) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: "SHOW_MESSAGE", payload: data.message });
          callback(200);
          onSuccess(data);
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
        }
      })
      .catch(function(error) {
        let body = error.response.data;
        let message = body ? body.message : error.message;
        dispatch({ type: FETCH_ERROR, payload: message });
        callback(body);
      });
  };
};

export const deleteResource = (resource, id) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .delete(`${baseUrl}/${resource}/${id}`)
      .then(({ data }) => {
        if (data.message) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: "SHOW_MESSAGE", payload: data.message });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const uploadFiles = (
  id,
  files,
  model,
  collection,
  callback = () => {}
) => {
  let formData = new FormData();
  formData.append("collection", collection);
  formData.append("files[]", files[0]);

  return (dispatch) => {
    dispatch({ type: "FETCH_START" });
    axios
      .post(`${baseUrl}/${model}/${id}/media-upload`, formData)
      .then(({ data }) => {
        if (data.message) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: "SHOW_MESSAGE", payload: data.message });
          dispatch({
            type: "UPLOAD_SUCCESS",
            payload: { ids: data.ids, name: collection },
          });
          callback(200);
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const deleteFiles = (id, files, model, collection) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .delete(`${baseUrl}/${model}/${id}/media-delete`, {
        data: {
          collection,
          mediaToDelete: files,
        },
      })
      .then(({ data }) => {
        if (data.message) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: "SHOW_MESSAGE", payload: data.message });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const fetchLists = (model) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .get(`${baseUrl}/${model}/lists`)
      .then(({ data }) => {
        if (data.data) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: "FETCH_LISTS_SUCCESS", payload: data.data });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const updateStatus = (id, model, status, callback = (res) => res) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_START" });
    axios
      .patch(`${baseUrl}/update-status`, { id, model, status })
      .then(({ data }) => {
        if (data.message) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: "SHOW_MESSAGE", payload: data.message });
          callback(200);
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const fetchCollectionNew = (resource, pagination, search, filters) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });

    var queryList = [];

    if (pagination.current) queryList.push(`page=${pagination.current}`);
    if (pagination.pageSize) queryList.push(`per_page=${pagination.pageSize}`);
    if (search) queryList.push(`search=${search}`);
    if (filters) queryList.push(`filters=${JSON.stringify(filters)}`);
    let query =
      queryList.length > 0
        ? queryList.reduce(
            (accumulator, currentValue) => accumulator + "&" + currentValue
          )
        : ``;

    axios
      .get(`${baseUrl}/${resource}?${query}`)
      .then(({ data }) => {
        if (data.data) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: `FETCH_${snakeCase(resource).toUpperCase()}_SUCCESS`,
            payload: data,
          });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};
