import axios from 'axios'

import {
  FETCH_ERROR,
  FETCH_TIMESHEET_SUCCESS,
} from '../../constants/ActionTypes'
export const baseUrl = process.env.REACT_APP_API_URL

export const fetchTimeSheet = (id, date) => {
  return (dispatch) => {
    dispatch({ type: 'FETCH_START' })
    axios
      .get(`${baseUrl}/employees/${id}/attendance?date=${date}&&type=timesheet`)
      .then(({ data }) => {
        if (data.data) {
          dispatch({ type: FETCH_TIMESHEET_SUCCESS, payload: data.data })
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error })
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message })
      })
  }
}
