// Customizer const
export const TOGGLE_COLLAPSED_NAV = "TOGGLE_COLLAPSE_MENU";
export const WINDOW_WIDTH = "WINDOW-WIDTH";
export const SWITCH_LANGUAGE = "SWITCH-LANGUAGE";

//Contact Module const
export const FETCH_START = "fetch_start";
export const FETCH_SUCCESS = "fetch_success";
export const FETCH_ERROR = "fetch_error";
export const SHOW_MESSAGE = "SHOW_MESSAGE";
export const HIDE_MESSAGE = "HIDE_MESSAGE";
export const ON_SHOW_LOADER = "ON_SHOW_LOADER";
export const ON_HIDE_LOADER = "ON_HIDE_LOADER";

// Common const
export const FETCH_LISTS_SUCCESS = "FETCH_LISTS_SUCCESS";
export const SHOW_SEARCH_BOX = "SHOW_SEARCH_BOX";
export const UPDATE_SEARCH = "UPDATE_SEARCH";

// Products Module const
export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
export const FETCH_PRODUCT_SUCCESS = "FETCH_PRODUCT_SUCCESS";

// Categories Module const
export const FETCH_CATEGORIES_SUCCESS = "FETCH_CATEGORIES_SUCCESS";
export const FETCH_CATEGORY_SUCCESS = "FETCH_CATEGORY_SUCCESS";

// Units Module const
export const FETCH_UNITS_SUCCESS = "FETCH_UNITS_SUCCESS";
export const FETCH_UNIT_SUCCESS = "FETCH_UNIT_SUCCESS";

// Brands Module const
export const FETCH_BRANDS_SUCCESS = "FETCH_BRANDS_SUCCESS";
export const FETCH_BRAND_SUCCESS = "FETCH_BRAND_SUCCESS";

// Colors Module const
export const FETCH_COLORS_SUCCESS = "FETCH_COLORS_SUCCESS";
export const FETCH_COLOR_SUCCESS = "FETCH_COLOR_SUCCESS";

// FulfillmentType Module const
export const FETCH_FULFILLMENT_TYPES_SUCCESS =
  "FETCH_FULFILLMENT_TYPES_SUCCESS";
export const FETCH_FULFILLMENT_TYPE_SUCCESS = "FETCH_FULFILLMENT_TYPE_SUCCESS";

// Reviews Module const
export const FETCH_REVIEWS_SUCCESS = "FETCH_REVIEWS_SUCCESS";
export const FETCH_REVIEW_SUCCESS = "FETCH_REVIEW_SUCCESS";

// Tickets Module const
export const FETCH_TICKETS_SUCCESS = "FETCH_TICKETS_SUCCESS";
export const FETCH_TICKET_SUCCESS = "FETCH_TICKET_SUCCESS";
export const ADD_TICKET_ACTION = "ADD_TICKET_ACTION";

// Surveys Module const
export const FETCH_SURVEYS_SUCCESS = "FETCH_SURVEYS_SUCCESS";
export const FETCH_SURVEY_SUCCESS = "FETCH_SURVEY_SUCCESS";

// Inbox Module const
export const FETCH_INBOX_SUCCESS = "FETCH_INBOX_SUCCESS";
export const FETCH_INBOX_SINGLE_SUCCESS = "FETCH_INBOX_SINGLE_SUCCESS";

// Notifications Module const
export const FETCH_NOTIFICATIONS_SUCCESS = "FETCH_NOTIFICATIONS_SUCCESS";
export const FETCH_NOTIFICATION_SUCCESS = "FETCH_NOTIFICATION_SUCCESS";

// Promo Code Module const
export const FETCH_PROMO_CODES_SUCCESS = "FETCH_PROMO_CODES_SUCCESS";
export const FETCH_PROMO_CODE_SUCCESS = "FETCH_PROMO_CODE_SUCCESS";

// Store Settings Module const
export const FETCH_STORE_SETTING_SUCCESS = "FETCH_STORE_SETTING_SUCCESS";

// Currencies Module const
export const FETCH_CURRENCIES_SUCCESS = "FETCH_CURRENCIES_SUCCESS";
export const FETCH_CURRENCY_SUCCESS = "FETCH_CURRENCY_SUCCESS";

// Fees Module const
export const FETCH_FEES_SUCCESS = "FETCH_FEES_SUCCESS";
export const FETCH_FEE_SUCCESS = "FETCH_FEE_SUCCESS";

// Contents Module const
export const FETCH_CONTENTS_SUCCESS = "FETCH_CONTENTS_SUCCESS";
export const FETCH_CONTENT_SUCCESS = "FETCH_CONTENT_SUCCESS";

// Clients Module const
export const FETCH_CLIENTS_SUCCESS = "FETCH_CLIENTS_SUCCESS";
export const FETCH_CLIENT_SUCCESS = "FETCH_CLIENT_SUCCESS";

// Client Addresses Module const
export const FETCH_CLIENT_ADDRESSES_SUCCESS = "FETCH_CLIENT_ADDRESSES_SUCCESS";

// Client Reviews Module const
export const FETCH_CLIENT_REVIEWS_SUCCESS = "FETCH_CLIENT_REVIEWS_SUCCESS";

// Client Reports Module const
export const FETCH_CLIENT_TICKETS_SUCCESS = "FETCH_CLIENT_TICKETS_SUCCESS";

// Orders Module const
export const FETCH_ORDERS_SUCCESS = "FETCH_ORDERS_SUCCESS";
export const FETCH_ORDER_SUCCESS = "FETCH_ORDER_SUCCESS";

// Users Module const
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";

// Roles Module const
export const FETCH_ROLES_SUCCESS = "FETCH_ROLES_SUCCESS";
export const FETCH_ROLE_SUCCESS = "FETCH_ROLE_SUCCESS";

// Permissions Module const
export const FETCH_PERMISSIONS_SUCCESS = "FETCH_PERMISSIONS_SUCCESS";
export const FETCH_PERMISSION_SUCCESS = "FETCH_PERMISSION_SUCCESS";
// employees Module constemployees
export const FETCH_EMPLOYEES_SUCCESS = "FETCH_EMPLOYEES_SUCCESS";
export const FETCH_EMPLOYEE_SUCCESS = "FETCH_EMPLOYEE_SUCCESS";
// salaries Module constemployees
export const FETCH_SALARIES_SUCCESS = "FETCH_SALARIES_SUCCESS";
export const FETCH_SALARY_SUCCESS = "FETCH_SALARY_SUCCESS";
// payrolls Module constemployees
export const FETCH_PAYROLLS_SUCCESS = "FETCH_PAYROLLS_SUCCESS";
export const FETCH_PAYROLL_SUCCESS = "FETCH_PAYROLL_SUCCESS";
// Lofg Module constemployees
export const FETCH_LOGS_SUCCESS = "FETCH_LOGS_SUCCESS";

// deductions Module constemployees
export const FETCH_DEDUCTIONS_SUCCESS = "FETCH_DEDUCTIONS_SUCCESS";
export const FETCH_DEDUCTION_SUCCESS = "FETCH_DEDUCTION_SUCCESS";
// bonuses Module constemployees
export const FETCH_BONUSES_SUCCESS = "FETCH_BONUSES_SUCCESS";
export const FETCH_BONUSE_SUCCESS = "FETCH_BONUSE_SUCCESS";
// allowances Module constemployees
export const FETCH_ALLOWANCES_SUCCESS = "FETCH_ALLOWANCES_SUCCESS";
export const FETCH_ALLOWANCE_SUCCESS = "FETCH_ALLOWANCE_SUCCESS";

// departments Module const
export const FETCH_DEPARTMENTS_SUCCESS = "FETCH_DEPARTMENTS_SUCCESS";
export const FETCH_DEPARTMENT_SUCCESS = "FETCH_DEPARTMENT_SUCCESS";
//Tree Structure
export const FETCH_TREE_STRUCTURE_SUCCESS = "FETCH_TREE_STRUCTURE_SUCCESS";
// Jobs Module const
export const FETCH_JOBS_SUCCESS = "FETCH_JOBS_SUCCESS";
export const FETCH_JOB_SUCCESS = "FETCH_JOB_SUCCESS";
// Branches Module const
export const FETCH_BRANCHES_SUCCESS = "FETCH_BRANCHES_SUCCESS";
export const FETCH_BRANCH_SUCCESS = "FETCH_BRANCH_SUCCESS";
// leave-categories Module const
export const FETCH_LEAVE_CATEGORIES_SUCCESS = "FETCH_LEAVE_CATEGORIES_SUCCESS";
export const FETCH_LEAVE_CATEGORIE_SUCCESS = "FETCH_LEAVE_CATEGORIE_SUCCESS";
// leave-categories Module const
export const FETCH_LEAVES_SUCCESS = "FETCH_LEAVES_SUCCESS";
export const FETCH_LEAVE_SUCCESS = "FETCH_LEAVE_SUCCESS";
// attendance-configs Module const
export const FETCH_ATTENDANCE_CONFIGS_SUCCESS =
  "FETCH_ATTENDANCE_CONFIGS_SUCCESS";
// Attendances Module const
export const FETCH_ATTENDANCES_SUCCESS = "FETCH_ATTENDANCES_SUCCESS";
export const FETCH_ATTENDANCE_SUCCESS = "FETCH_ATTENDANCE_SUCCESS";
// Weekly hours Module const
export const FETCH_WEEKLY_HOURS_SUCCESS = "FETCH_WEEKLY_HOURS_SUCCESS";
// Timesheet Module const
export const FETCH_TIMESHEET_SUCCESS = "FETCH_TIMESHEET_SUCCESS";
// Integrations Module const
export const FETCH_INTEGRATIONS_SUCCESS = "FETCH_INTEGRATIONS_SUCCESS";
export const FETCH_INTEGRATION_SUCCESS = "FETCH_INTEGRATION_SUCCESS";

// Locations Module const
export const FETCH_LOCATIONS_SUCCESS = "FETCH_LOCATIONS_SUCCESS";
export const FETCH_LOCATION_SUCCESS = "FETCH_LOCATION_SUCCESS";

//Auth const
export const SIGNUP_USER = "SIGNUP_USER";
export const SIGNUP_USER_SUCCESS = "SIGNUP_USER_SUCCESS";
export const SIGNIN_GOOGLE_USER = "SIGNIN_GOOGLE_USER";
export const SIGNIN_GOOGLE_USER_SUCCESS = "SIGNIN_GOOGLE_USER_SUCCESS";
export const SIGNIN_FACEBOOK_USER = "SIGNIN_FACEBOOK_USER";
export const SIGNIN_FACEBOOK_USER_SUCCESS = "SIGNIN_FACEBOOK_USER_SUCCESS";
export const SIGNIN_TWITTER_USER = "SIGNIN_TWITTER_USER";
export const SIGNIN_TWITTER_USER_SUCCESS = "SIGNIN_TWITTER_USER_SUCCESS";
export const SIGNIN_GITHUB_USER = "SIGNIN_GITHUB_USER";
export const SIGNIN_GITHUB_USER_SUCCESS = "signin_github_user_success";
export const SIGNIN_USER = "SIGNIN_USER";
export const SIGNIN_USER_SUCCESS = "SIGNIN_USER_SUCCESS";
export const SIGNOUT_USER = "SIGNOUT_USER";
export const SIGNOUT_USER_SUCCESS = "SIGNOUT_USER_SUCCESS";
export const INIT_URL = "INIT_URL";
export const USER_DATA = "user_data";
export const USER_TOKEN_SET = "user_token_set";

export const SET_FORMATED_LIST = "SET_FORMATED_LIST";

// Projects Module const
export const FETCH_PROJECTS_SUCCESS = "FETCH_PROJECTS_SUCCESS";
export const FETCH_PROJECT_SUCCESS = "FETCH_PROJECT_SUCCESS";
// Projects Tasks Module const
export const FETCH_PROJECT_TASKS_SUCCESS = "FETCH_PROJECT_TASKS_SUCCESS";
export const FETCH_PROJECT_TASK_SUCCESS = "FETCH_PROJECT_TASK_SUCCESS";

// Tasks Module const
export const FETCH_TASKS_SUCCESS = "FETCH_TASKS_SUCCESS";
export const FETCH_TASK_SUCCESS = "FETCH_TASK_SUCCESS";
// Forms Module const
export const FETCH_CONTRACT_DATA_SUCCESS = "FETCH_CONTRACT_DATA_SUCCESS";
export const FETCH_JOB_OFFER_SUCCESS = "FETCH_JOB_OFFER_SUCCESS";

// Projects Module const
export const FETCH_PROPOSALS_SUCCESS = "FETCH_PROPOSALS_SUCCESS";
export const FETCH_PROPOSAL_SUCCESS = "FETCH_PROPOSAL_SUCCESS";