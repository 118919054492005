/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";

import { Table, Progress, Tooltip, Select } from 'antd';

const { Option } = Select;

export function AdvanceTablesWidget2({ className, data }) {
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState();
  // let dispatch = useDispatch();
  // let projects = useSelector((state) => state.projects);
  // const { loading } = useSelector((state) => state.common);

  // const [pagination, setPagination] = useState({
  //   current: 1,
  //   pageSize: 5,
  //   total: 10,
  // });
  // const [data, setData] = useState([]);

  // useEffect(() => {
  //   dispatch(fetchCollection("projects", pagination));
  // }, []);

  // useEffect(() => {
  //   if (projects.index) {
  //     setData(projects.index.data);
  //     setPagination({ ...pagination, total: projects.index.meta.total });
  //   }
  // }, [projects]);

  // let handleChange = (pagination) => {
  //   dispatch(fetchCollection("projects", pagination));
  //   setPagination(pagination);
  // };

  useEffect(() => {
    console.log('projects', data);

    data.forEach(d => {
      const estimated = Object.keys(d.timespent).reduce((acc, key) => acc + parseInt(d.timespent[key].estimated), 0);
      const spent = Object.keys(d.timespent).reduce((acc, key) => acc + parseInt(d.timespent[key].spent), 0);
      const percentage = estimated > 0 ? ((spent / estimated) * 100).toFixed(0) : 0
  
      const project = {
        id: d.id,
        name: d.name,
        estimated: estimated,
        spent: spent,
        percentage: percentage,
        departments: []
      };
  
      Object.keys(d.timespent).forEach(key => {
        project.departments.push({
          name: key,
          estimated: d.timespent[key].estimated,
          spent: d.timespent[key].spent,
          percentage: d.timespent[key].estimated > 0 ? ((d.timespent[key].spent / d.timespent[key].estimated) * 100).toFixed(0) : 0
        })
      })
  
      projects.push(project)
    });
  
    projects.sort(function(a, b){
      if(a.name < b.name) { return -1; }
      if(a.name > b.name) { return 1; }
      return 0;
    })

    setProjects(projects)
    setSelectedProject(projects[0])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])
  

  const columns = [
    // {
    //   dataIndex: "icon",
    //   render: (text, record) => (
    //     <div className="symbol symbol-50 symbol-light mr-1">
    //       <span className="symbol-label">
    //         <SVG
    //           src={toAbsoluteUrl("/media/svg/misc/006-plurk.svg")}
    //           className="h-50 align-self-center"
    //         ></SVG>
    //       </span>
    //     </div>
    //   ),
    // },

    {
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <div style={{ textAlign: 'right' }}>
          <span>
            {text}
          </span>
        </div>
      ),
    },
    // {
    //   dataIndex: "status",
    //   key: "status",
    //   render: (text, record) => (
    //     <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
    //       Active
    //     </span>
    //   ),
    // },
    {
      dataIndex: "percentage",
      key: "percentage",
      width: '200px',
      render: (text, record) => (
        <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
          <Tooltip title={`${record.spent} spent / ${record.estimated} estimated`}>
            <Progress percent={text} />
          </Tooltip>
        </span>
      ),
    }
    // ,
    // {
    //   dataIndex: "estimation",
    //   key: "estimation",
    //   render: (text, record) => (
    //     <>
    //       {text?.actual_total > text.total ? (
    //         <span className="label label-lg label-light-primary label-inline">
    //           Complated
    //         </span>
    //       ) : (
    //         <span className="label label-lg label-light-danger label-inline">
    //           Not Complated
    //         </span>
    //       )}
    //     </>
    //   ),
    // }
    // ,

    // {
    //   key: "id",
    //   render: (record) => (
    //     <span>
    //       <Link to={`time-cards/projects/${record.id}/edit`}>
    //         <span className="svg-icon svg-icon-md svg-icon-primary">
    //           <SVG
    //             src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
    //           ></SVG>
    //         </span>
    //       </Link>
    //     </span>
    //   ),
    // },
  ];
  // const [key, setKey] = useState("Month");

  const onSelectProject = (id) => {
    console.log('onSelectProject', id)

    const project = projects.find(p => p.id === id)

    setSelectedProject(project)
  }

  return (
    <div className={`card card-custom ${className}`}>
      {/* Head */}
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label font-weight-bolder text-dark">
            Projects
          </span>
          {/* <span className="text-muted mt-3 font-weight-bold font-size-sm">
            More than 400+ new members
          </span> */}
        </h3>
        <div className="card-toolbar">
          <Select
              value={selectedProject?.id}
              onChange={(value) => onSelectProject(value)}
              placeholder="Select projects"
              style={{minWidth: '250px'}}
              popupMatchSelectWidth={false}
            >
              {projects.map((p) => {
                return (
                  <Option
                    value={p.id}
                    key={p.id}
                    name={p.name}
                  >
                    {p.name}
                  </Option>
                );
              })}
            </Select>
          {/* <Tab.Container defaultActiveKey={key}>
            <Nav
              as="ul"
              onSelect={(_key) => setKey(_key)}
              className="nav nav-pills nav-pills-sm nav-dark-75"
            >
              <Nav.Item className="nav-item" as="li">
                <Nav.Link
                  eventKey="Month"
                  className={`nav-link py-2 px-4 ${
                    key === "Month" ? "active" : ""
                  }`}
                >
                  Month
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="nav-item" as="li">
                <Nav.Link
                  eventKey="Week"
                  className={`nav-link py-2 px-4 ${
                    key === "Week" ? "active" : ""
                  }`}
                >
                  Week
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Tab.Container>*/}
        </div> 
      </div>
      {/* Body */}
      <div className="card-body pt-3 pb-0">
        {selectedProject && <div className="row">
          <div className="col-md-6" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Progress type="dashboard" size={200} percent={selectedProject.percentage} />
          </div>
          <div className="col-md-6">
            {selectedProject.departments.length > 0 && <div className="table-responsive">
              <Table
                className="projectTable-home table table-borderless table-vertical-center"
                rowKey={(record) => record.name}
                tableLayout="auto"
                columns={columns}
                dataSource={selectedProject.departments}
                showHeader={false}
                pagination={false}
                // onChange={handleChange}
                // loading={loading}
              />
            </div>}
          </div>
        </div>}
      </div>
    </div>
  );
}
