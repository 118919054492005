import axios from 'axios'

import { FETCH_ERROR, FETCH_SUCCESS } from '../../constants/ActionTypes'
export const baseUrl = process.env.REACT_APP_API_URL

export const fetchSalariesResource = (resource, id, month, singular) => {
  return (dispatch) => {
    dispatch({ type: 'FETCH_START' })
    axios
      .get(`${baseUrl}/${resource}/${id}?month=${month}`)
      .then(({ data }) => {
        if (data.data) {
          dispatch({ type: FETCH_SUCCESS })
          dispatch({
            type: `FETCH_${singular.toUpperCase()}_SUCCESS`,
            payload: data.data,
          })
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error })
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message })
      })
  }
}
export const saveMonth = (month) => {
  return {
    type: 'SAVE_MONTH',
    payload: month,
  }
}
