import React from "react";
import { useField } from "formik";
import TextField from "@material-ui/core/TextField";
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";
const getFieldCSSClasses = (touched, errors) => {
  const classes = ["form-control", "form-control-solid"];
  if (touched && errors) {
    classes.push("is-invalid-select");
  }

  if (touched && !errors) {
    classes.push("is-valid-select");
  }

  return classes.join(" ");
};

export function CustomSelect({
  label,
  withFeedbackLabel = true,
  type = "text",
  customFeedbackLabel,
  children,
  ...props
}) {
  const [field, meta] = useField(props);
  const { touched, error } = meta;
  return (
    <>
      {label && <label>{label}</label>}
      <TextField
        className={getFieldCSSClasses(touched, error)}
        {...field}
        {...props}
        select
      >
        {!children ? <span></span> : children}
      </TextField>
      {withFeedbackLabel && (
        <FieldFeedbackLabel
          erros={error}
          touched={touched}
          label={label}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}
