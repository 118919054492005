/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Pagination, Skeleton } from "antd";
import SVG from "react-inlinesvg";
import { Dropdown } from "react-bootstrap";
import { fetchCollection } from "../../../../redux/actions";
import { toAbsoluteUrl } from "../../../_helpers";
import { DropdownCustomToggler, DropdownMenu1 } from "../../dropdowns";

export function ListsWidget3({ className }) {
  let dispatch = useDispatch();
  let departments = useSelector((state) => state.departments);
  const { loading } = useSelector((state) => state.common);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    total: 10,
  });
  useEffect(() => {
    dispatch(fetchCollection("departments", pagination));
  }, []);
  useEffect(() => {
    if (departments.index) {
      setPagination({ ...pagination, total: departments.index.meta.total });
    }
  }, [departments]);
  let handleChange = (page) => {
    setPagination({ ...pagination, current: page });
  };
  useEffect(() => {
    dispatch(fetchCollection("departments", pagination));
  }, [pagination.current]);

  return (
    <>
      <div className={`card card-custom ${className}`}>
        {/* Head */}
        <div className="card-header border-0">
          <h3 className="card-title font-weight-bolder text-dark">
            Departments
          </h3>
        </div>
        {/* Body */}
        <div className="card-body pt-2">
          {loading ? (
            <Skeleton />
          ) : (
            <>
              {departments?.index?.data?.map((department) => {
                return (
                  <div
                    className="d-flex align-items-center mb-10"
                    key={department.id}
                  >
                    <div className="symbol symbol-40 symbol-light-success mr-5">
                      <span className="symbol-label">
                        <SVG
                          className="h-75 align-self-end"
                          src={toAbsoluteUrl(
                            "/media/svg/avatars/009-boy-4.svg"
                          )}
                        ></SVG>
                      </span>
                    </div>
                    <div className="d-flex flex-column flex-grow-1 font-weight-bold">
                      {department.name}
                    </div>
                    <span>{department.employees_count}</span>
                  </div>
                );
              })}

              {/* <div className="d-flex align-items-center mb-10">
            <div className="symbol symbol-40 symbol-light-success mr-5">
              <span className="symbol-label">
                <SVG
                  className="h-75 align-self-end"
                  src={toAbsoluteUrl("/media/svg/avatars/006-girl-3.svg")}
                ></SVG>
              </span>
            </div>
            <div className="d-flex flex-column flex-grow-1 font-weight-bold">
              <a
                href="#"
                className="text-dark text-hover-primary mb-1 font-size-lg"
              >
                Anne Clarc
              </a>
              <span className="text-muted">PHP, SQLite, Artisan CLI</span>
            </div>
            <ItemDropdown item="" />
          </div> */}

              {/* <div className="d-flex align-items-center mb-10">
            <div className="symbol symbol-40 symbol-light-success mr-5">
              <span className="symbol-label">
                <SVG
                  className="h-75 align-self-end"
                  src={toAbsoluteUrl("/media/svg/avatars/011-boy-5.svg")}
                ></SVG>
              </span>
            </div>
            <div className="d-flex flex-column flex-grow-1 font-weight-bold">
              <a
                href="#"
                className="text-dark text-hover-primary mb-1 font-size-lg"
              >
                Kristaps Zumman
              </a>
              <span className="text-muted">PHP, SQLite, Artisan CLI</span>
            </div>
            <ItemDropdown item="" />
          </div> */}
              {/* <div className="d-flex align-items-center mb-10">
            <div className="symbol symbol-40 symbol-light-success mr-5">
              <span className="symbol-label">
                <SVG
                  className="h-75 align-self-end"
                  src={toAbsoluteUrl("/media/svg/avatars/015-boy-6.svg")}
                ></SVG>
              </span>
            </div>
            <div className="d-flex flex-column flex-grow-1 font-weight-bold">
              <a
                href="#"
                className="text-dark text-hover-primary mb-1 font-size-lg"
              >
                Ricky Hunt
              </a>
              <span className="text-muted">PHP, SQLite, Artisan CLI</span>
            </div>
            <ItemDropdown item="" />
          </div> */}

              {/* <div className="d-flex align-items-center mb-2">
            <div className="symbol symbol-40 symbol-light-success mr-5">
              <span className="symbol-label">
                <SVG
                  className="h-75 align-self-end"
                  src={toAbsoluteUrl("/media/svg/avatars/016-boy-7.svg")}
                ></SVG>
              </span>
            </div>
            <div className="d-flex flex-column flex-grow-1 font-weight-bold">
              <a
                href="#"
                className="text-dark text-hover-primary mb-1 font-size-lg"
              >
                Carles Puyol
              </a>
              <span className="text-muted">PHP, SQLite, Artisan CLI</span>
            </div>
            <ItemDropdown item="" />
          </div> */}
              <Pagination
                current={pagination?.current}
                pageSize={pagination?.pageSize}
                total={pagination.total}
                onChange={handleChange}
                style={{ textAlign: "right", marginTop: "20px" }}
                className="pagination-home"
              />
            </>
          )}
        </div>
      </div>
    </>
  );
}

const ItemDropdown = ({ item }) => {
  return (
    <>
      <Dropdown className="dropdown-inline" alignRight>
        <Dropdown.Toggle id="dropdown-toggle-top" as={DropdownCustomToggler}>
          <i className="ki ki-bold-more-hor" />
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
          <DropdownMenu1 />
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};
